import { loadJobList } from './loadJobList';
import { loadEvents } from './loadEvents';
import { loadPosts } from './loadPosts';
import { deletePosts } from './deletePosts';
import { downloadPostAttachment } from './downloadPostAttachment';

export const jobShim = {
    loadJobList,
    loadEvents,
    loadPosts,
    deletePosts,
    downloadPostAttachment,
};
